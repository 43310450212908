let cultureVideo = document.querySelector('.js-culture-video');
let playButton = document.querySelector('.js-play-button');
let pauseButton = document.querySelector('.js-pause-button');
let cultureTagline = document.querySelectorAll('.js-culture-tagline');

document.querySelector('.js-salvador-dali-loop').play();
let zImages = document.querySelectorAll('.js-zi-video');

zImages.forEach(item => {
  item.addEventListener('hover', () => {
    item.play();
  })
});


cultureVideo.onended = () => {
  playButton.style.opacity = '1';
  pauseButton.style.opacity = '0';
  playButton.style.zIndex = '1';
  pauseButton.style.zIndex = '-1';
  cultureTagline.forEach((item) => {
    item.style.opacity = '1';
  })
};

playButton.addEventListener('click', () => {
  cultureVideo.play();
  playButton.style.opacity = '0';
  pauseButton.style.opacity = '1';
  pauseButton.style.zIndex = '1';
  playButton.style.zIndex = '-1';
  cultureTagline.forEach((item) => {
    item.style.opacity = '0';
  })
});

pauseButton.addEventListener('click', () => {
  cultureVideo.pause();
  playButton.style.opacity = '1';
  pauseButton.style.opacity = '0';
  playButton.style.zIndex = '1';
  pauseButton.style.zIndex = '-1';
  cultureTagline.forEach((item) => {
    item.style.opacity = '1';
  })
});

$(window).load(function(){
  setTimeout(function(){
    $('.loader').hide();
    $('.main-main').css("display", "block");
    $('.header').css("display", "block");
  })
});

$(window).on('scroll', function(){
  $currSection = $('section').filter(function() {
    var $this = $(this);
    var offset = $this.offset().top;
    //var view = $( window ).height() / 2;
    var view = 56;

    return $(window).scrollTop() >= offset - view && offset + ($this.outerHeight() - view) >= $(window).scrollTop();
  });
  if($currSection.children().hasClass('curiosity-diversity-section') || $currSection.children().hasClass('contact-section')){
    $('.logo').css('fill', 'white');
    $('.contact-btn').css('color', 'white');
    $('.circle').css('fill', 'white');
    $('.eclipse-menu').css('fill', 'white');
    $('.section-menu__navigation__item a').css('color', 'black');

  } else {
    $('.logo').css('fill', 'black');
    $('.contact-btn').css('color', 'black');
    $('.circle').css('fill', 'black');
    $('.eclipse-menu').css('fill', 'black');
    $('.section-menu__navigation__item a').css('color', 'white');
  }
});
let sectionNav = document.querySelector('.js-section-nav');
let sectionMenu = document.querySelector('.js-section-menu');
let eclipseMenu = document.querySelector('.eclipse-menu');
let sectionMenuBack = document.querySelector('.js-section-menu__back');
let sectionMenuNavigation = document.querySelector('.js-section-menu__navigation');

let isOpened = false;

sectionNav.addEventListener('click', () => {
  if(isOpened) {
    sectionMenu.style.opacity = '0';
    sectionMenu.style.visibility = 'hidden';
    isOpened = false;
  } else {
    sectionMenu.style.visibility = 'visible';
    sectionMenu.style.opacity = '1';
    isOpened = true;
  }
});

$(document).mouseup(e => {
  if (!e.target.classList.contains('js-section-menu') && !e.target.classList.contains('eclipse-menu')) // ... nor a descendant of the container
  {
    sectionMenu.style.opacity = '0';
    sectionMenu.style.visibility = 'hidden';
    isOpened = false;
 }
});


sectionMenuNavigation.addEventListener('click', () => {
  if(isOpened) {
    sectionMenu.style.opacity = '0';
    sectionMenu.style.visibility = 'hidden';
    isOpened = false;
  }
});

sectionMenuBack.addEventListener('click', () => {
  if(isOpened) {
    sectionMenu.style.opacity = '0';
    sectionMenu.style.visibility = 'hidden';
    isOpened = false;
  }
});

$(function () {
  count = 0;
  wordsArray = ["Ello", "Aye", "Hola", "Bueans", "Ei", "E'yo", "你好", "Hello", 'Bonjour'];
  setInterval(function () {
    count++;
    $(".all-ears-cohort-layout-hello").fadeOut(0, function () {
      $(this).text(wordsArray[count % wordsArray.length]).fadeIn(0);
    });
  }, 700);
});